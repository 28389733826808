import { Component, OnInit } from '@angular/core';
import { LaboratoryService } from '../../service/laboratory.service';
import { LaboratoryFind } from '../../model/laboratory-find';
import { Laboratory } from '../../model/laboratory';
import { Observable } from 'rxjs';
import { LaboratoryFindWith } from '../../model/laboratory-find-with';
import { ApplicationFindWith } from '../../../application/model/application-find-with';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { ApplicationService } from '../../../application/service/application.service';
import { AviService } from '../../../avi/service/avi.service';
import { Avi } from '../../../avi/model/avi';
import { Find } from '../../../base/model/find';

@Component({
  templateUrl: './laboratory-search.component.html',
  styleUrls: [ './laboratory-search.component.scss' ]
})
export class LaboratorySearchComponent implements OnInit {
  private laboratoryFind: LaboratoryFind;
  private labs: Observable<Laboratory[]>;
  avis: Avi[];

  private showTerminated = false;
  private orderByKey: 'referenceNumber' | 'name' | 'latestApplication.validUntilDate' = 'latestApplication.validUntilDate';
  private orderByDir: 'ASC' | 'DESC' = 'ASC';
  private avi: number = -1;

  constructor(
    private laboratoryService: LaboratoryService,
    private applicationService: ApplicationService,
    private aviService: AviService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.aviService.find(new Find()).subscribe(avis => this.avis = avis);
    this.activatedRoute.queryParams.subscribe(params => {
      this.showTerminated = params.showTerminated === 'true';
      this.orderByKey = params.orderByKey ? params.orderByKey : 'latestApplication.validUntilDate';
      this.orderByDir = params.orderByDir ? params.orderByDir : 'ASC';
      this.refreshSearchResults();
    });
  }

  private refreshSearchResults() {
    this.labs = null;

    this.laboratoryFind = new LaboratoryFind();
    this.laboratoryFind.where.excludeTerminated = !this.showTerminated;
    this.laboratoryFind.orders = [ { key: this.orderByKey, dir: this.orderByDir } ];

    this.laboratoryFind.with = new LaboratoryFindWith();
    this.laboratoryFind.with.latestApplication = new ApplicationFindWith();
    this.laboratoryFind.with.latestApplication.totalNumberOfResearchesPerYear = true;

    this.labs = this.laboratoryService.find(this.laboratoryFind);
  }

  searchFormUpdated(newParams: any) {
    this.router.navigate(['laboratory'], {
      queryParams: this.buildQueryParams(newParams)
    });
  }

  buildQueryParams(newParams: any) {
    return {
      orderByKey: this.orderByKey,
      orderByDir: this.orderByDir,
      showTerminated: this.showTerminated,
      ...newParams
    };
  }

  getStatus(lab: Laboratory) {
    if (lab.properties.terminated) {
      return 'terminated';
    } else if (lab.with.latestApplication) {
      if (lab.with.latestApplication.properties.validityGrantedDate) {
        if (lab.with.latestApplication.properties.validForNow
          || _.lte(new Date().toISOString(), lab.with.latestApplication.properties.validUntil)) {
          return 'valid';
        } else {
          return 'expired';
        }
      } else {
        return 'in_progress';
      }
    } else {
      return 'no_application';
    }
  }

}
