import {Component, Input} from '@angular/core';
import {Application} from '../../model/application';
import {Router} from '@angular/router';

@Component({
  selector: 'app-application-header',
  templateUrl: './application-header.component.html',
  styleUrls: ['./application-header.component.scss']
})
export class ApplicationHeaderComponent {
  @Input()
  application: Application;

  constructor(
    private router: Router
  ) {}

  toBasics() {
    this.router.navigate(['application', this.application.properties.id, 'basics']);
  }
}
