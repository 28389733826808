import {Find} from '../../base/model/find';
import {ApplicationFindWith} from './application-find-with';
import {ApplicationFindWhere} from './application-find-where';
import {ApplicationFindOrder} from './application-find-order';

export class ApplicationFind extends Find {
  with = new ApplicationFindWith();
  where = new ApplicationFindWhere();
  orders: ApplicationFindOrder[];
}
