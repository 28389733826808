import { Component, OnInit } from '@angular/core';
import {ApplicationService} from '../application/service/application.service';
import {ApplicationFind} from '../application/model/application-find';
import {Application} from '../application/model/application';
import {Observable, of, Subject} from 'rxjs';
import {Laboratory} from '../laboratory/model/laboratory';
import {debounceTime, distinctUntilChanged, switchMap} from 'rxjs/operators';
import {LaboratoryFind} from '../laboratory/model/laboratory-find';
import {LaboratoryService} from '../laboratory/service/laboratory.service';
import {ApplicationPhase} from '../application/enum/application-phase';
import {LaboratoryFindWith} from '../laboratory/model/laboratory-find-with';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  phases = ApplicationPhase;
  applications: Observable<Application[]>;

  laboratorySearchTerms = new Subject<string>();
  laboratories: Observable<Laboratory[]>;

  constructor(
    public applicationService: ApplicationService,
    private laboratoryService: LaboratoryService
  ) { }

  ngOnInit() {
    const find = new ApplicationFind();
    find.with.laboratory = new LaboratoryFindWith();
    find.with.statement = true;
    find.where.inProgress = true;
    find.orders = [{
      key: 'arrivalDate',
      dir: 'ASC'
    }];
    find.limit = 20;

    this.applications = this.applicationService.find(find);

    this.laboratories = this.laboratorySearchTerms.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(term => {
        if (term.length < 3) {
          return of([]);
        }

        const laboratoryFind = new LaboratoryFind();
        laboratoryFind.where.referenceNumberOrNameContains = term;
        laboratoryFind.limit = 10;

        return this.laboratoryService.find(laboratoryFind);
      })
    );
  }
}
