import * as XLSX from 'xlsx';
import * as _ from 'lodash';
import {Research} from '../model/research';
import {DeferredPromise} from '../../deferred-promise';

export class ResearchImport {
  static readonly numberColumn = 'Tutk.nro';
  static readonly abbreviationColumn = 'Nimilyhenne';
  static readonly nameColumn = 'Tutkimuksen nimi';
  static readonly amountColumn = 'Määrä / vuosi';
  static readonly qualityControlColumn = 'Sis. laad. tarkkailu';
  static readonly labQualityColumn = 'Labquality';
  static readonly ukneqasColumn = 'UKNEQAS';
  static readonly qcmdColumn = 'QCMD';
  static readonly accreditedColumn = 'Akkreditoitu tutkimus';

  static readonly trueString = 'kyllä';
  static readonly falseString = 'ei';

  researches: Research[] = [];
  invalidColumnNames: string[] = [];
  missingColumnNames: string[] = [];
  errors: {
    rowNum: number,
    column: string,
    value: any
  }[] = [];

  private deferred = new DeferredPromise<ResearchImport>();
  private columns: string[];

  import(file: any, columns: string[]) {
    this.columns = columns;

    const reader = new FileReader();
    reader.onload = (onLoadEvent: any) => this.onLoad(onLoadEvent.target.result);
    reader.readAsArrayBuffer(file);

    return this.deferred.promise;
  }

  private onLoad(data: any) {
    const workbook = XLSX.read(new Uint8Array(data), {type: 'array'});
    const rows = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]) as {[key: string]: any}[];

    if (rows.length === 0) {
      this.deferred.resolve(this);
      return;
    }

    this.validateColumnNames(Object.keys(rows[0]));
    this.researches = rows.map((row, index) => this.buildRow(row, index + 2));

    this.deferred.resolve(this);
  }

  private validateColumnNames(columnNames: string[]) {
    this.invalidColumnNames = columnNames.filter(columnName => ! _.includes(this.columns, columnName));
    this.missingColumnNames = this.columns.filter(columnName => ! _.includes(columnNames, columnName));
  }

  private buildRow(row: {[key: string]: any}, rowNum: number) {
    const research = new Research();

    research.properties.number = this.parseStringValue(row, ResearchImport.numberColumn, rowNum);
    this.errorIfNull(research.properties.number, row, ResearchImport.numberColumn, rowNum);

    research.properties.abbreviation = this.parseStringValue(row, ResearchImport.abbreviationColumn, rowNum);
    this.errorIfNull(research.properties.abbreviation, row, ResearchImport.abbreviationColumn, rowNum);

    research.properties.name = this.parseStringValue(row, ResearchImport.nameColumn, rowNum);
    this.errorIfNull(research.properties.name, row, ResearchImport.nameColumn, rowNum);

    research.properties.amount = this.parseIntegerValue(row, ResearchImport.amountColumn, rowNum);
    research.properties.qualityControl = this.parseBooleanValue(row, ResearchImport.qualityControlColumn, rowNum);
    research.properties.labQuality = this.parseIntegerValue(row, ResearchImport.labQualityColumn, rowNum);
    research.properties.ukneqas = this.parseIntegerValue(row, ResearchImport.ukneqasColumn, rowNum);
    research.properties.qcmd = this.parseIntegerValue(row, ResearchImport.qcmdColumn, rowNum);
    research.properties.accredited = this.parseBooleanValue(row, ResearchImport.accreditedColumn, rowNum);

    return research;
  }

  private parseBooleanValue(row: {[key: string]: any}, column: string, rowNum: number) {
    let value = this.parseRawValue(row, column);
    if (value === null) {
      return null;
    }

    value = _.trim(value);
    if (
      ! _.isString(value) ||
      (value !== ResearchImport.trueString && value !== ResearchImport.falseString)
    ) {
      this.errors.push({rowNum, column, value: row[column]});
      return null;
    }

    return value === ResearchImport.trueString;
  }

  private parseIntegerValue(row: {[key: string]: any}, column: string, rowNum: number) {
    let value = this.parseRawValue(row, column);
    if (value === null) {
      return null;
    }

    value = parseInt(value, 10);
    if (! _.isInteger(value)) {
      this.errors.push({rowNum, column, value: row[column]});
      return null;
    }

    return value;
  }

  private parseStringValue(row: {[key: string]: any}, column: string, rowNum: number) {
    let value = this.parseRawValue(row, column);
    if (value === null) {
      return null;
    }

    value = _.trim(value);
    if (! _.isString(value) || value === '') {
      this.errors.push({rowNum, column, value: row[column]});
      return null;
    }

    return value;
  }

  private parseRawValue(row: {[key: string]: any}, column: string) {
    if (! this.columns.find(c => c === column)) {
      return null;
    }

    if (! (column in row) || row[column] === null) {
      return null;
    }

    return row[column];
  }

  private errorIfNull(value: any, row: {[key: string]: any}, column: string, rowNum: number) {
    if (value === null) {
      this.errors.push({rowNum, column, value: row[column]});
    }
  }
}
