export class StatementProperties {
  id: number;
  applicationId: number;
  statementDate: Date;
  statementText: string;
  statementCategory: number;
  firstSigner: string;
  secondSigner: string;
  thlDiary: string;
  thlDiarySerial: number;
  thlDiaryClass: string;
  thlDiaryYear: number;
  draft: boolean;
  aviContactInfo: string;
  laboratoryName: string;
  laboratoryReferenceNumber: number;
  requestDate: Date;
  aviDiary: string;
}
