import {Injectable} from '@angular/core';
import {Service} from '../../base/service/service';
import {HttpClient} from '@angular/common/http';
import {Avi} from '../model/avi';

@Injectable({
  providedIn: 'root'
})
export class AviService extends Service<Avi> {
  constructor(
    public http: HttpClient
  ) {
    super(
      'api/avi',
      http
    );
  }
}
