import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {UserService} from './user/service/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private router: Router
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.userService.refreshCurrentUser().pipe(
      map(user => {
        if (user) {
          return true;
        }

        this.router.navigate(['login', {redirect: state.url}]);
        return false;
      })
    );
  }
}
