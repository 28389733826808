import {Directive, ElementRef} from '@angular/core';
import {DropdownInputDirective} from './dropdown-input.directive';

@Directive({
  selector: '[appDropdownMenu]',
  exportAs: 'dropdownMenuDirective'
})
export class DropdownMenuDirective {
  element: HTMLElement;

  constructor(
    elementRef: ElementRef
  ) {
    this.element = elementRef.nativeElement;
  }

  select(index: number) {
    DropdownInputDirective.OPEN_INPUT.select(index);
  }
}
