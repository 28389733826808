import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {User} from '../model/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  currentUser: User;

  constructor(
    private router: Router,
    private http: HttpClient
  ) {}

  logout() {
    return this.http.post('api/user/logout', null).pipe(
      tap(() => this.currentUser = null)
    );
  }

  login(username: string, password: string) {
    return this.http.post<User>('api/user/login', {username, password}).pipe(
      tap(user => this.currentUser = user)
    );
  }

  refreshCurrentUser() {
    return this.http.get<User>('api/user/current').pipe(
      tap(user => this.currentUser = user)
    );
  }

  isLoggedIn() {
    return !! this.currentUser;
  }
}
