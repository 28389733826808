import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {Laboratory} from '../../../laboratory/model/laboratory';
import {Application} from '../../model/application';
import {LaboratoryService} from '../../../laboratory/service/laboratory.service';
import {ApplicationService} from '../../service/application.service';
import {LaboratoryFind} from '../../../laboratory/model/laboratory-find';
import {Util} from '../../../base/util';
import {ActivatedRoute, Router} from '@angular/router';
import {ApplicationFind} from '../../model/application-find';
import {switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {LaboratoryFormComponent} from '../../../laboratory/component/laboratory-form/laboratory-form.component';
import {LaboratoryFindWith} from '../../../laboratory/model/laboratory-find-with';
import {mustBeNullValidator} from '../../../base/validator';
import {AviService} from '../../../avi/service/avi.service';
import {Find} from '../../../base/model/find';
import {Avi} from '../../../avi/model/avi';

@Component({
  templateUrl: './application-basics.component.html',
  styleUrls: ['./application-basics.component.scss']
})
export class ApplicationBasicsComponent implements AfterViewInit {
  @ViewChild(LaboratoryFormComponent, {static: false})
  laboratoryForm: LaboratoryFormComponent;

  methodForm: FormGroup;
  applicationForm: FormGroup;

  laboratory: Laboratory;
  application: Application;

  supervisingLaboratoryRefNum: number;
  avis: Avi[];

  constructor(
    private formBuilder: FormBuilder,
    private laboratoryService: LaboratoryService,
    private messageService: MessageService,
    public translateService: TranslateService,
    private applicationService: ApplicationService,
    private route: ActivatedRoute,
    private router: Router,
    private aviService: AviService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    this.route.params.pipe(
      switchMap(params => {
        const id = params.applicationId;
        if (!id) {
          return of(new Application());
        }

        const find = new ApplicationFind();
        find.with.laboratory = new LaboratoryFindWith();
        find.with.supervisingLaboratory = new LaboratoryFindWith();

        return this.applicationService.findById(id, find);
      }),
      tap(application => {
        this.application = application;

        if (application.with.laboratory) {
          this.laboratory = application.with.laboratory;
        }
      })
    ).subscribe(() => this.buildForms());

    this.aviService.find(new Find()).subscribe(avis => this.avis = avis);
  }

  buildForms() {
    this.methodForm = this.formBuilder.group({
      referenceNumber: ['', Validators.required]
    });

    this.applicationForm = this.formBuilder.group({
      category: ['', Validators.required],
      aviDiary: ['', Validators.required],
      initDate: [''],
      arrivalDate: [''],
      requestDate: [''],
      inspectingRequirements: [''],
      supervisingLaboratoryId: [''],
      aviId: ['', Validators.required]
    });

    Util.entityPropertiesToForm(this.application, this.applicationForm);

    this.applicationForm.valueChanges.subscribe(value => {
      if (value.category === 1) {
        this.applicationForm.get('supervisingLaboratoryId').setValidators([mustBeNullValidator]);
      } else if (value.category === 3) {
        this.applicationForm.get('supervisingLaboratoryId').setValidators([Validators.required]);
      }

      // Hack to update forms validness after server errors.
      // TODO: Should not be needed: https://juristr.com/blog/2019/02/display-server-side-validation-errors-with-angular/
      this.applicationForm.setValue(value, {emitEvent: false});
    });

    this.moveToMethodSelection();
    this.changeDetectorRef.detectChanges();
  }

  moveToMethodSelection() {
    this.methodForm.enable();
    this.laboratoryForm.disable();

    this.applicationForm.enable();
    if (! this.application.properties.id) {
      this.applicationForm.disable();
    }
  }

  moveToLaboratoryView() {
    this.methodForm.enable();
    this.laboratoryForm.disable();
    this.applicationForm.enable();
  }

  moveToLaboratoryEdit() {
    this.methodForm.disable();
    this.laboratoryForm.enable();
    this.applicationForm.disable();
  }

  loadLaboratory() {
    const find = new LaboratoryFind();
    find.where.referenceNumberIs = this.methodForm.controls.referenceNumber.value;

    this.laboratoryService.findOne(find).subscribe( laboratory => {
      this.methodForm.reset();

      if (! laboratory) {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('laboratory.notFound')
        });

        return;
      }

      this.laboratory = laboratory;
      this.moveToLaboratoryView();
    });
  }

  newLaboratory() {
    this.laboratory = new Laboratory();
    this.moveToLaboratoryEdit();
  }

  onCancelLaboratoryEdit() {
    if (! this.laboratory.properties.id) {
      this.laboratory = null;
      this.moveToMethodSelection();
      return;
    }

    this.moveToLaboratoryView();
  }

  saveApplication() {
    this.application.properties.laboratoryId = this.laboratory.properties.id;
    Util.formToEntityProperties(this.applicationForm, this.application);

    return this.applicationService.save(this.application.properties).subscribe(response => {
      if (! response.validation.valid) {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('laboratory.saveFailed')
        });

        Util.errorsToForm(response, this.applicationForm);

        return;
      }

      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('application.saveSucceed')
      });

      this.router.navigate(['application', response.properties.id, 'contacts']);
    });
  }

  changeSupervisingLaboratory(event: any) {
    const find = new LaboratoryFind();
    find.where.referenceNumberIs = this.supervisingLaboratoryRefNum;

    this.laboratoryService.findOne(find).subscribe(laboratory => {
      if (! laboratory) {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('laboratory.notFound')
        });

        return;
      }

      this.application.with.supervisingLaboratory = laboratory;
      this.applicationForm.get('supervisingLaboratoryId').setValue(laboratory.properties.id);
    });

    this.supervisingLaboratoryRefNum = null;
    event.preventDefault();
  }

  removeSupervisingLaboratory() {
    this.application.with.supervisingLaboratory = null;
    this.applicationForm.get('supervisingLaboratoryId').setValue(null);
  }
}
