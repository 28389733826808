import {Entity} from '../../base/model/entity';
import {ApplicationProperties} from './application-properties';
import {ApplicationWith} from './application-with';

export class Application extends Entity<ApplicationProperties, ApplicationWith> {
  constructor() {
    super();

    this.properties = new ApplicationProperties();
    this.with = new ApplicationWith();
  }
}
