import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateonly'
})
export class DateOnlyPipe implements PipeTransform {

  transform(value: number | Date) {
    if (! value) { return; }

    return new Date(value).toLocaleString('fi', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
    });
}

}
