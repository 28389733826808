import {DistinctLaboratoryNameFindWhere} from './distinct-laboratory-name-find-where';

export class DistinctLaboratoryNameFind {
  limit: number;
  where: DistinctLaboratoryNameFindWhere;

  constructor() {
    this.where = new DistinctLaboratoryNameFindWhere();
  }
}
