import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Service } from '../../base/service/service';
import { StatementSignerTemplate } from '../model/statement-signer-template';
import { StatementSignerTemplateProperties } from '../model/statement-signer-template-properties';
import { StatementSignerTemplateSaveResponse } from '../model/statement-signer-template-save-response';

@Injectable({
  providedIn: 'root'
})
export class StatementSignerTemplateService extends Service<StatementSignerTemplate> {
  constructor(
    public http: HttpClient
  ) {
    super(
      'api/statement-signer-template',
      http
    );
  }

  save(properties: StatementSignerTemplateProperties) {
    return this.http.post<StatementSignerTemplateSaveResponse>(this.baseUrl + '/save', properties);
  }
}
