import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ApplicationService} from '../../service/application.service';
import {Application} from '../../model/application';
import {ApplicationFind} from '../../model/application-find';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ContactRole} from '../../enum/contact-role';
import {Contact} from '../../model/contact';
import {Tally} from '../../model/tally';
import {Util} from '../../../base/util';
import {ContactService} from '../../service/contact.service';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {switchMap} from 'rxjs/operators';
import {EMPTY, of} from 'rxjs';
import {TallyService} from '../../service/tally-service';
import {
  fullTimeOrPartTimeMissingValidator,
  manYearsMissingValidator,
  manYearsTooHighValidator,
  manYearsTooLowValidator
} from '../../validator/tally.validator';
import {phoneNumberValidator} from '../../../base/validator';
import {TallyRole} from '../../enum/tally-role';
import {LaboratoryFindWith} from '../../../laboratory/model/laboratory-find-with';

@Component({
  templateUrl: './application-contacts.component.html',
  styleUrls: ['./application-contacts.component.scss']
})
export class ApplicationContactsComponent implements OnInit {
  form: FormGroup;
  ContactRole = ContactRole;

  application: Application;
  contacts: Contact[];
  tallies: Tally[];

  constructor(
    private route: ActivatedRoute,
    private applicationService: ApplicationService,
    private formBuilder: FormBuilder,
    private contactService: ContactService,
    private tallyService: TallyService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    const find = new ApplicationFind();
    find.with.laboratory = new LaboratoryFindWith();
    find.with.contacts = true;
    find.with.tallies = true;

    this.route.params.subscribe(params =>
      this.applicationService.findById(params.applicationId, find).subscribe(application => {
        this.application = application;

        this.prepareContacts();
        this.prepareTallies();
        this.buildForm();
      })
    );
  }

  prepareContacts() {
    this.contacts = [
      ContactRole.ChiefInChargeOfClinicalMicrobiologyBusiness,
      ContactRole.PersonInChargeOfDailyLaboratoryBusiness,
      ContactRole.OtherFormSigner
    ].map(role => {
      const contact = new Contact();
      const properties = this.application.with.contacts
        .find(findProperties => findProperties.role === role);

      if (properties) {
        contact.properties = properties;
      }

      contact.properties.applicationId = this.application.properties.id;
      contact.properties.role = role;
      return contact;
    });
  }

  prepareTallies() {
    this.tallies = [
      TallyRole.ClinicalMicrobiologySpecialist,
      TallyRole.GraduatingClinicalMicrobiologySpecialist,
      TallyRole.HospitalMicrobiologist,
      TallyRole.GraduatingHospitalMicrobiologist,
      TallyRole.BioanalystOrLaboratoryAssistant,
      TallyRole.OtherLaboratoryContact
    ].map(role => {
      const tally = new Tally();
      const properties = this.application.with.tallies
        .find(findProperties => findProperties.role === role);

      if (properties) {
        tally.properties = properties;
      }

      tally.properties.applicationId = this.application.properties.id;
      tally.properties.role = role;
      return tally;
    });
  }

  buildForm() {
    this.form = this.formBuilder.group({
      signer: [this.application.properties.contactFormSigner],
      contacts: this.formBuilder.array(this.contacts.map(this.buildContactFormGroup.bind(this))),
      tallies: this.formBuilder.array(this.tallies.map(this.buildTallyFormGroup.bind(this)))
    });
  }

  buildContactFormGroup(contact: Contact) {
    const group = this.formBuilder.group({
      name: [],
      education: [],
      phone: ['', phoneNumberValidator],
      email: ['', Validators.email]
    });

    Util.entityPropertiesToForm(contact, group);
    return group;
  }

  buildTallyFormGroup(tally: Tally) {
    const group = this.formBuilder.group({
      fullTime: [],
      partTime: [],
      manYears: []
    }, {validators: [
      manYearsMissingValidator,
      fullTimeOrPartTimeMissingValidator,
      manYearsTooLowValidator,
      manYearsTooHighValidator
    ]});

    Util.entityPropertiesToForm(tally, group);
    return group;
  }

  signerFormControl() {
    return this.form.get('signer') as FormControl;
  }

  contactFormGroups() {
    return (this.form.get('contacts') as FormArray).controls as FormGroup[];
  }

  tallyFormGroups() {
    return (this.form.get('tallies') as FormArray).controls as FormGroup[];
  }

  save() {
    this.saveApplication().pipe(
      switchMap(() => this.saveContacts()),
      switchMap(() => this.saveTallies())
    ).subscribe(() => {

    });
  }

  saveApplication() {
    this.application.properties.contactFormSigner = this.form.get('signer').value;
    return this.applicationService.save(this.application.properties).pipe(
      switchMap(response => {
        if (! response.validation.valid) {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('application.saveFailed')
          });

          return EMPTY;
        }

        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('application.saveSucceed')
        });

        this.application.properties.contactFormSigner = response.properties.contactFormSigner;

        return of(response);
      })
    );
  }

  saveContacts() {
    const array = this.form.get('contacts') as FormArray;
    const groups = array.controls as FormGroup[];
    groups.forEach((group, index) => Util.formToEntityProperties(group, this.contacts[index]));

    return this.contactService
      .saveAll(this.contacts.map(contact => contact.properties))
      .pipe(switchMap(response => {
        if (! response.valid) {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('contact.saveFailed')
          });

          groups.forEach((group, index) => Util.errorsToForm(response.responses[index], group));
          return EMPTY;
        }

        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('contact.saveSucceed')
        });

        this.contacts.forEach((contact, index) => contact.properties = response.responses[index].properties);
        return of(response);
      }));
  }

  saveTallies() {
    const array = this.form.get('tallies') as FormArray;
    const groups = array.controls as FormGroup[];
    groups.forEach((group, index) => Util.formToEntityProperties(group, this.tallies[index]));

    return this.tallyService
      .saveAll(this.tallies.map(tally => tally.properties))
      .pipe(switchMap(response => {
        if (! response.valid) {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('tally.saveFailed')
          });

          groups.forEach((group, index) => Util.errorsToForm(response.responses[index], group));
          return EMPTY;
        }

        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('tally.saveSucceed')
        });

        this.tallies.forEach((tally, index) => tally.properties = response.responses[index].properties);
        return of(response);
      }));
  }
}
