import {Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {DropdownMenuDirective} from './dropdown-menu.directive';

@Directive({
  selector: '[appDropdownInput]',
  exportAs: 'dropdownInputDirective'
})
export class DropdownInputDirective {
  static OPEN_INPUT: DropdownInputDirective;

  private element: HTMLInputElement;

  @Input('appDropdownInput')
  menu: DropdownMenuDirective;

  privateLength: number;
  @Input()
  set length(length: number) {
    this.privateLength = length;
    this.rewindActive();
  }
  get length() {
    return this.privateLength;
  }

  @Input()
  active;

  @Output()
  activeChange = new EventEmitter<number>();

  @Output()
  selection = new EventEmitter<number>();

  constructor(
    elementRef: ElementRef
  ) {
    this.element = elementRef.nativeElement;
  }

  @HostListener('input')
  onInput() {
    this.open();
  }

  @HostListener('focus')
  onFocus() {
    this.open();
    this.rewindActive();
    this.setPosition();
  }

  @HostListener('blur')
  onBlur() {
    this.close();
  }

  @HostListener('keydown.arrowDown', ['$event'])
  onArrowDown(event: KeyboardEvent) {
    event.preventDefault();

    let index = this.active + 1;
    if (index >= this.length) {
      index = 0;
    }

    this.setActive(index);
  }

  @HostListener('keydown.arrowUp', ['$event'])
  onArrowUp(event: KeyboardEvent) {
    event.preventDefault();

    let index = this.active - 1;
    if (index < 0) {
      index = this.length - 1;
    }

    this.setActive(index);
  }

  @HostListener('keydown.enter', ['$event'])
  onEnter(event: KeyboardEvent) {
    event.preventDefault();

    this.select(this.active);
  }

  @HostListener('window:resize')
  onResize() {
    if (DropdownInputDirective.OPEN_INPUT === this) {
      this.setPosition();
    }
  }

  open() {
    DropdownInputDirective.OPEN_INPUT = this;
    this.menu.element.style.display = 'block';
  }

  close() {
    window.setTimeout(() => {
      if (DropdownInputDirective.OPEN_INPUT !== this) {
        return;
      }

      this.menu.element.style.display = 'none';
    }, 300);
  }

  rewindActive() {
    this.setActive(0);
  }

  setActive(index: number) {
    this.active = index;
    this.activeChange.emit(this.active);
  }

  setPosition() {
    const inputPosition = this.element.getBoundingClientRect();

    this.menu.element.style.top = window.scrollY + inputPosition.bottom + 'px';
    this.menu.element.style.left = window.scrollX + inputPosition.left + 'px';
  }

  select(index: number) {
    this.selection.emit(index);
    this.close();
  }
}
