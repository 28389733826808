import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Application} from '../model/application';
import {Service} from '../../base/service/service';
import {ApplicationProperties} from '../model/application-properties';
import {ApplicationSaveResponse} from '../model/application-save-response';
import {ApplicationPhase} from '../enum/application-phase';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService extends Service<Application> {
  constructor(
    public http: HttpClient
  ) {
    super(
      'api/application',
      http
    );
  }

  save(properties: ApplicationProperties) {
    return this.http.post<ApplicationSaveResponse>(this.baseUrl + '/save', properties);
  }

  phase(application: Application) {
    if (! application.properties.inspectingRequirements) {
      return ApplicationPhase.StatementRequestReceived;
    }

    if (! application.properties.statementProduced || ! application.properties.registerExtracted) {
      return ApplicationPhase.ProduceStatementAndRegisterExtract;
    }

    if (! application.properties.validityGrantedDate) {
      return ApplicationPhase.RegisterConclusion;
    }

    return ApplicationPhase.Finished;
  }
}
