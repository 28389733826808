import {Component, OnInit} from '@angular/core';
import {LaboratoryService} from '../../service/laboratory.service';
import {Laboratory} from '../../model/laboratory';
import {ActivatedRoute} from '@angular/router';
import {LaboratoryFind} from '../../model/laboratory-find';
import {map, switchMap} from 'rxjs/operators';
import {ApplicationFindWith} from '../../../application/model/application-find-with';
import {ApplicationPhase} from '../../../application/enum/application-phase';
import {ApplicationService} from '../../../application/service/application.service';
import {ApplicationFind} from "../../../application/model/application-find";

@Component({
  templateUrl: './laboratory-profile.component.html',
  styleUrls: ['./laboratory-profile.component.scss']
})
export class LaboratoryProfileComponent implements OnInit {
  phase = ApplicationPhase;
  laboratory: Laboratory;

  constructor(
    public applicationService: ApplicationService,
    private laboratoryService: LaboratoryService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.activatedRoute.params.pipe(
      switchMap(params => {
        const find = new LaboratoryFind();
        find.with.validApplication = new ApplicationFindWith();

        return this.laboratoryService.findById(params.laboratoryId, find);
      }),
      switchMap(laboratory => {
        const find = new ApplicationFind();
        find.where.laboratoryIdIs = laboratory.properties.id;
        find.orders = [{key: 'arrivalDate', dir: 'DESC NULLS LAST'}];

        return this.applicationService.find(find).pipe(
          map(applications => {
            laboratory.with.applications = applications;
            return laboratory;
          })
        );
      })
    ).subscribe(laboratory => this.laboratory = laboratory);
  }
}
