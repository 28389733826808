import {Component, Input} from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Component({
  selector: 'app-validation-error-message',
  template: `
    <app-message [closeable]="true" type="error" *ngIf="visible()">
      <div
        *ngFor="let entry of control.errors | keyvalue"
        [translate]="'validation.' + entry.key"
      ></div>
    </app-message>
  `
})
export class ValidationErrorMessageComponent {
  @Input()
  control: AbstractControl;

  @Input()
  hideIfUntouched = true;

  visible() {
    let visible = this.control.invalid;

    if (this.hideIfUntouched) {
      visible = visible && this.control.touched;
    }

    return visible;
  }
}
