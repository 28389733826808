export class LaboratoryProperties {
  id: number;
  referenceNumber: string;
  address: string;
  city: string;
  name: string;
  businessIdentityCode: string;
  billingContact: string;
  organisationName: string;
  organisationAddress: string;
  organisationPostalCode: string;
  organisationCity: string;
  phoneNumber: string;
  postalCode: string;
  terminated = false;
}
