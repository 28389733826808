import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Statement } from '../model/statement';
import { Service } from '../../base/service/service';
import { StatementProperties } from '../model/statement-properties';
import { StatementSaveResponse } from '../model/statement-save-response';

@Injectable({
  providedIn: 'root'
})
export class StatementService extends Service<Statement> {
  constructor(
    public http: HttpClient
  ) {
    super(
      'api/statement',
      http
    );
  }

  save(properties: StatementProperties) {
    return this.http.post<StatementSaveResponse>(this.baseUrl + '/save', properties);
  }

  buildThlIdentifier(statement: StatementProperties): string {
    if (! statement) {
      return;
    }

    return 'THL ' + this.padLeft(statement.thlDiarySerial, 4) +
      '/' + statement.thlDiaryClass +
      '/' + statement.thlDiaryYear;
  }

  padLeft(num: number, size: number): string {
    let s = num + '';
    while (s.length < size) { s = '0' + s; }
    return s;
  }
}
