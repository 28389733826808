import { Component } from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from './user/service/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    public userService: UserService,
    private router: Router,
    private translate: TranslateService
  ) {
    translate.setDefaultLang('fi');
    translate.use('fi');
  }

  logout() {
    this.userService.logout().subscribe(
      () => this.router.navigate(['login', {logout: true}])
    );
  }
}
