// Deferred promise keeps async caller code out of promise constructor

export class DeferredPromise<T> {
  promise: Promise<T>;

  private innerResolve: (value?: T | PromiseLike<T>) => void;
  private innerReject: (reason?: any) => void;

  constructor() {
    this.promise = new Promise<T>(
      (
        resolve: (value?: T | PromiseLike<T>) => void,
        reject: (reason?: any) => void
      ) => {
        this.innerResolve = resolve;
        this.innerReject = reject;
      }
    );
  }

  resolve(value?: T | PromiseLike<T>) {
    this.innerResolve(value);
  }

  reject(reason?: any) {
    this.innerReject(reason);
  }
}
