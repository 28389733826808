export class ResearchProperties {
  id: number;
  applicationId: number;
  subcontractId: number;
  number: string;
  abbreviation: string;
  name: string;
  amount: number;
  qualityControl: boolean;
  labQuality: number;
  ukneqas: number;
  qcmd: number;
  accredited: boolean;
  researchCodeOfKuntaliitto: boolean;
}
