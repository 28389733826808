import { Component, OnInit, Input } from '@angular/core';
import { StatementTextTemplateService } from '../service/statement-text-template.service';
import { StatementTextTemplateFind } from '../model/statement-text-template-find';
import { StatementProperties } from '../model/statement-properties';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { StatementTextTemplateProperties } from '../model/statement-text-template-properties';

@Component({
  selector: 'app-statement-text-component',
  templateUrl: './statement-text.component.html',
  styleUrls: ['./statement-text.component.scss']
})
export class StatementTextComponent implements OnInit {

  @Input()
  statement: StatementProperties;

  textOptions: StatementTextTemplateProperties[];
  selectedTextIndex: number = 0;
  editableText: string;
  edit: boolean = false;

  constructor(
    private statementTextTemplateService: StatementTextTemplateService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.statementTextTemplateService.find(new StatementTextTemplateFind()).subscribe(statementTextTemplate => {
      this.textOptions = statementTextTemplate.map(statementTextTemplate => statementTextTemplate.properties);
      if (this.statement.statementCategory) {
        this.selectedTextIndex = this.textOptions.findIndex(template => (template.id === this.statement.statementCategory));
        this.textOptions[this.selectedTextIndex].statementText = this.statement.statementText;
      } else if (this.textOptions.length > 0) {
        this.statement.statementText = this.textOptions[0].statementText;
        this.statement.statementCategory = this.textOptions[0].id;
      }
    });
  }

  textSelectChange(selectedTextIndex) {
    this.selectedTextIndex = selectedTextIndex;
    this.statement.statementText = this.textOptions[this.selectedTextIndex].statementText;
    this.statement.statementCategory = this.textOptions[this.selectedTextIndex].id;
  }

  modify() {
    this.editableText = this.textOptions[this.selectedTextIndex].statementText;
    this.edit = true;
  }

  saveText() {
    this.edit = false;
    this.statement.statementText = this.editableText;
    this.textOptions[this.selectedTextIndex].statementText = this.editableText;
  }

  saveAsTemplate() {
    this.edit = false;
    let textTemplate = new StatementTextTemplateProperties();
    textTemplate.statementText = this.editableText;
    textTemplate.id = this.textOptions[this.selectedTextIndex].id;

    this.statementTextTemplateService.save(textTemplate).subscribe(response => {
      if (!response.validation.valid) {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('statement.savingTextTemplateFailed')
        });
      } else {
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('statement.savingTextTemplateSucceeded')
        });
        textTemplate = response.properties;
        this.textOptions[this.selectedTextIndex].statementText = textTemplate.statementText;
        this.statement.statementText = textTemplate.statementText;
      }
    });
  }

  cancelEditing() {
    this.edit = false;
  }
}
