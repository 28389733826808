import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationFind } from '../application/model/application-find';
import { ApplicationService } from '../application/service/application.service';
import { StatementService } from './service/statement.service';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { StatementFind } from './model/statement-find';
import { StatementProperties } from './model/statement-properties';
import { Location } from '@angular/common';
import {LaboratoryFindWith} from '../laboratory/model/laboratory-find-with';

@Component({
  selector: 'app-request-for-statement',
  templateUrl: './request-for-statement.component.html',
  styleUrls: ['./request-for-statement.component.scss']
})
export class RequestForStatementComponent implements OnInit {

  statement: StatementProperties;
  applicationId: number;

  constructor(
    private applicationService: ApplicationService,
    private statementService: StatementService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {
  }

  ngOnInit() {
    const statementDate = new Date();
    this.applicationId = this.route.snapshot.params.applicationId;
    const statementFind = new StatementFind();
    statementFind.where.applicationIdIs = this.applicationId;
    this.statementService.findOne(statementFind).subscribe(statement => {
      if (statement) {
        this.statement = statement.properties;
      } else {
        this.statement = new StatementProperties();
        this.statement.statementDate = statementDate;
        this.statement.draft = true;

        this.copyApplicationProperties(this.statement);
      }
    });
  }

  copyApplicationProperties(statement: StatementProperties) {
    const applicationFind = new ApplicationFind();
    applicationFind.with.laboratory = new LaboratoryFindWith();
    applicationFind.with.avi = true;

    return this.applicationService.findById(this.applicationId, applicationFind).subscribe(application => {
      statement.applicationId = application.properties.id;
      statement.aviContactInfo = application.with.avi.contactInfoForStatement;
      statement.laboratoryName = application.with.laboratory.properties.name;
      statement.laboratoryReferenceNumber = +application.with.laboratory.properties.referenceNumber;
      statement.requestDate = application.properties.requestDate;
      statement.aviDiary = application.properties.aviDiary;
    });
  }

  signer1stSelectChange(signer) {
    this.statement.firstSigner = signer;
  }

  signer2ndSelectChange(signer) {
    this.statement.secondSigner = signer;
  }

  previewStatement() {
    this.upsertStatement();
  }

  upsertStatement() {
    this.statementService.save(this.statement).subscribe(response => {
      if (!response.validation.valid) {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('statement.saveFailed')
        });
      } else {
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('statement.saveSucceeded')
        });
        this.router.navigate(['application', this.statement.applicationId, 'statement']);
      }
    });
  }

  editLaboratory() {
    this.router.navigate(['application', this.statement.applicationId, 'basics']);
  }

  goBack() {
    this.location.back();
  }
}
