import {Find} from '../../base/model/find';
import {LaboratoryFindWhere} from './laboratory-find-where';
import {LaboratoryFindWith} from './laboratory-find-with';
import { LaboratoryFindOrder } from './laboratory-find-order';

export class LaboratoryFind extends Find {
  where = new LaboratoryFindWhere();
  with = new LaboratoryFindWith();
  orders: LaboratoryFindOrder[];
}
