import {Injectable} from '@angular/core';
import {ResearchCombined} from '../model/research-combined';
import {HttpClient} from '@angular/common/http';
import {ResearchCombinedFind} from '../model/research-combined-find';
import {SaveAllResponse} from '../../base/model/save-all-response';
import {ResearchProperties} from '../model/research-properties';
import {Service} from '../../base/service/service';
import {Research} from '../model/research';
import {ResearchSaveResponse} from '../model/research-save-response';
import { ResearchTemplateFind } from '../model/research-template-find';
import { ResearchTemplate } from '../model/research-template';

@Injectable({
  providedIn: 'root'
})
export class ResearchService extends Service<Research> {

  constructor(
    public http: HttpClient
  ) {
    super(
      'api/research',
      http
    );
  }

  findCombined(find: ResearchCombinedFind) {
    return this.http.post<ResearchCombined[]>('api/research/find-combined', find);
  }

  findResearchTemplate(find: ResearchTemplateFind) {
    return this.http.post<ResearchTemplate[]>('api/research/find-research-template', find);
  }

  saveAll(propertiesList: ResearchProperties[]) {
    return this.http.post<SaveAllResponse<ResearchSaveResponse>>(this.baseUrl + '/saveAll', propertiesList);
  }

  deleteAll(ids: number[]) {
    return this.http.delete<boolean>(this.baseUrl, {
      params: {ids: ids.map(id => id.toString())}
    });
  }
}
