import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { LandingComponent } from './landing/landing.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageComponent } from './message/message.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ValidationErrorMessageComponent } from './message/validation-error-message.component';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ApplicationContactsComponent } from './application/component/application-contacts/application-contacts.component';
import { ApplicationContactsContactFormComponent } from './application/component/application-contacts/application-contacts-contact-form.component';
import { ApplicationHeaderComponent } from './application/component/application-header/application-header.component';
import { registerLocaleData } from '@angular/common';
import localeFi from '@angular/common/locales/fi';
import { ApplicationBasicsComponent } from './application/component/application-basics/application-basics.component';
import { ApplicationResearchesComponent } from './application/component/application-researches/application-researches.component';
import { DropdownInputDirective } from './dropdown-input.directive';
import { DropdownMenuDirective } from './dropdown-menu.directive';
import { StatementComponent } from './statement/statement.component';
import { DateOnlyPipe } from './base/pipe/dateonly.pipe';
import { TimeOnlyPipe } from './base/pipe/timeonly.pipe';
import { RequestForStatementComponent } from './statement/request-for-statement.component';
import { ApplicationResearchesResearchTableComponent } from './application/component/application-researches/application-researches-research-table.component';
import { ApplicationResearchesSubcontractPanelsComponent } from './application/component/application-researches/application-researches-subcontract-panels.component';
import { StatementSignerComponent } from './statement/statement-signer/statement-signer.component';
import { StatementTextComponent } from './statement/statement-text-component/statement-text.component';
import { RegisterExtractComponent } from './register-extract/register-extract.component';
import { LaboratoryProfileComponent } from './laboratory/component/laboratory-profile/laboratory-profile.component';
import {LaboratoryFormComponent} from './laboratory/component/laboratory-form/laboratory-form.component';
import { LaboratorySearchComponent } from './laboratory/component/laboratory-search/laboratory-search.component'
import {ApplicationProduceComponent} from './application/component/application-produce/application-produce.component';
import {ApplicationValidityComponent} from './application/component/application-validity/application-validity.component';

registerLocaleData(localeFi, 'fi');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LandingComponent,
    MessageComponent,
    ApplicationBasicsComponent,
    ValidationErrorMessageComponent,
    ApplicationContactsComponent,
    ApplicationContactsContactFormComponent,
    ApplicationResearchesComponent,
    ApplicationHeaderComponent,
    DropdownInputDirective,
    DropdownMenuDirective,
    StatementComponent,
    DateOnlyPipe,
    TimeOnlyPipe,
    RequestForStatementComponent,
    ApplicationResearchesResearchTableComponent,
    ApplicationResearchesSubcontractPanelsComponent,
    StatementSignerComponent,
    StatementTextComponent,
    RegisterExtractComponent,
    LaboratoryProfileComponent,
    LaboratoryFormComponent,
    LaboratorySearchComponent,
    LaboratoryFormComponent,
    ApplicationProduceComponent,
    ApplicationValidityComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'fi'},
    MessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
