import {Component, OnInit} from '@angular/core';
import {ApplicationService} from '../../service/application.service';
import {ApplicationFind} from '../../model/application-find';
import {ActivatedRoute} from '@angular/router';
import {switchMap} from 'rxjs/operators';
import {Application} from '../../model/application';
import {StatementService} from '../../../statement/service/statement.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Util} from '../../../base/util';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {LaboratoryFindWith} from '../../../laboratory/model/laboratory-find-with';

@Component({
  templateUrl: './application-produce.component.html',
  styleUrls: ['./application-produce.component.scss']
})
export class ApplicationProduceComponent implements OnInit {
  application: Application;
  form: FormGroup;

  constructor(
    private applicationService: ApplicationService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private translateService: TranslateService,
    public statementService: StatementService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.pipe(
      switchMap(params => {
        const find = new ApplicationFind();
        find.with.laboratory = new LaboratoryFindWith();
        find.with.statement = true;

        return this.applicationService.findById(params.applicationId, find);
      })
    ).subscribe(application => {
      this.application = application;
      this.setupForm();
    });
  }

  setupForm() {
    this.form = this.formBuilder.group({
      registerExtracted: [this.application.properties.registerExtracted],
      statementProduced: [this.application.properties.statementProduced]
    });

    this.form.valueChanges.subscribe(value => {
      Util.formToEntityProperties(this.form, this.application);
      this.applicationService.save(this.application.properties).subscribe(response => {
        if (! response.validation.valid) {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('application.saveFailed')
          });

          Util.errorsToForm(response, this.form);

          return;
        }

        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('application.saveSucceed')
        });
      });
    });
  }
}
