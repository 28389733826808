export class ApplicationProperties {
  id: number;
  laboratoryId: number;
  supervisingLaboratoryId: number;
  arrivalDate: string;
  aviDiary: string;
  category: number;
  initDate: string;
  inspectingRequirements: boolean;
  requirementsInspected: boolean;
  contactFormSigner: string;
  aviId: number;
  registerExtracted: boolean;
  statementProduced: boolean;
  validityGrantedDate: string;
  validUntil: string;
  validForNow: boolean;
  requestDate: Date;
}
