import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StatementFind } from './model/statement-find';
import { StatementService } from './service/statement.service';
import { Statement } from './model/statement';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-statement',
  templateUrl: './statement.component.html',
  styleUrls: ['./statement.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StatementComponent implements OnInit {

  applicationId: number;
  statement: Statement;

  constructor(
    private statementService: StatementService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.applicationId = this.route.snapshot.params.applicationId;
    const find = new StatementFind();
    find.where.applicationIdIs = this.applicationId;
    this.statementService.findOne(find).subscribe(statement => this.statement = statement);
  }

  toEditMode() {
    this.statement.properties.draft = true;
    this.updateStatement();
    this.router.navigate(['application', this.applicationId, 'request-for-statement']);
  }

  createStatement() {
    this.statement.properties.draft = false;
    this.updateStatement();
  }

  updateStatement() {
    return this.statementService.save(this.statement.properties).subscribe(response => {
      if (!response.validation.valid) {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('statement.saveFailed')
        });
      } else {
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('statement.saveSucceeded')
        });
      }
    });
  }

  print() {
    window.print();
  }
}
