import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-message',
  template: `
    <div
      class="message"
      *ngIf="! closed"
      [ngClass]="{
    error: type === TYPE_ERROR
  }"
    >
      <div class="message-content">
        <ng-content></ng-content>
      </div>
      <span class="close" (click)="closed = true" *ngIf="closeable">&times;</span>
    </div>
  `,
})
export class MessageComponent implements OnInit {
  TYPE_ERROR = 'error';

  @Input()
  closeable = false;

  @Input()
  type: string;

  closed = false;

  constructor() {}

  ngOnInit() {}

}
