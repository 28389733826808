import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Laboratory} from '../model/laboratory';
import {Service} from '../../base/service/service';
import {LaboratoryProperties} from '../model/laboratory-properties';
import {LaboratorySaveResponse} from '../model/laboratory-save-response';

@Injectable({
  providedIn: 'root'
})
export class LaboratoryService extends Service<Laboratory> {
  constructor(
    public http: HttpClient
  ) {
    super(
      'api/laboratory',
      http
    );
  }

  save(properties: LaboratoryProperties) {
    return this.http.post<LaboratorySaveResponse>(this.baseUrl + '/save', properties);
  }
}
