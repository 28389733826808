import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Location } from '@angular/common';
import { ApplicationService } from '../application/service/application.service';
import { ApplicationFind } from '../application/model/application-find';
import { ActivatedRoute } from '@angular/router';
import { SubcontractFindWith } from '../subcontract/model/subcontract-find-with';
import { Application } from '../application/model/application';
import { ContactProperties } from '../application/model/contact-properties';
import { Subcontract } from '../application/model/subcontract';
import { LaboratoryFindWith } from '../laboratory/model/laboratory-find-with';
import { ApplicationFindWith } from '../application/model/application-find-with';
import { Laboratory } from '../laboratory/model/laboratory';
import { Research } from '../application/model/research';
import { ResearchWith } from '../application/model/research-with';
import { TranslateService } from '@ngx-translate/core';
import { ResearchService } from '../application/service/research.service';
import { ResearchTemplateFind } from '../application/model/research-template-find';
import { ResearchTemplate } from '../application/model/research-template';

@Component({
  selector: 'app-register-extract',
  templateUrl: './register-extract.component.html',
  styleUrls: ['./register-extract.component.scss']
})
export class RegisterExtractComponent implements OnInit {
  readonly numOfResearchesOnPage = 28;
  readonly numOfLaboratoriesOnPage = 68;

  application: Application;
  supervisedCategory2Labs: Laboratory[];
  supervisedCategory3Labs: Laboratory[];
  dateOfRegisterExtract = new Date();
  chiefInChargeOfClinicalMicrobiologyBusiness: ContactProperties;
  personInChargeOfDailyLaboratoryBusiness: ContactProperties;
  researchTemplate: ResearchTemplate[];

  constructor(
    private applicationService: ApplicationService,
    private route: ActivatedRoute,
    private location: Location,
    private researchService: ResearchService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.researchService.findResearchTemplate(new ResearchTemplateFind()).subscribe(researchTemplate => {
      this.researchTemplate = researchTemplate;
      this.dateOfRegisterExtract = new Date();
      const applicationId = this.route.snapshot.params.applicationId;
      this.applicationService.findById(applicationId, this.buildApplicationFind()).subscribe(application => {
        this.application = application;
        if (this.application) {
          this.chiefInChargeOfClinicalMicrobiologyBusiness = this.application.with.contacts
            .find(contact => contact.role === 'ChiefInChargeOfClinicalMicrobiologyBusiness');
          this.personInChargeOfDailyLaboratoryBusiness = this.application.with.contacts
            .find(contact => contact.role === 'PersonInChargeOfDailyLaboratoryBusiness');

          this.supervisedCategory2Labs = this.application.with.laboratory.with.validSupervisedApplications
            .filter(supervisedApplication => supervisedApplication.properties.category === 2)
            .filter(supervisedApplication => ! supervisedApplication.with.laboratory.properties.terminated)
            .map(supervisedApplication => supervisedApplication.with.laboratory);

          this.supervisedCategory3Labs = this.application.with.laboratory.with.validSupervisedApplications
            .filter(supervisedApplication => supervisedApplication.properties.category === 3)
            .filter(supervisedApplication => ! supervisedApplication.with.laboratory.properties.terminated)
            .map(supervisedApplication => supervisedApplication.with.laboratory);

          this.checkResearchCodesOfKuntaliitto();
        }
      });
    });
  }

  checkResearchCodesOfKuntaliitto() {
    this.application.with.researches.forEach(research => {
      research.properties.researchCodeOfKuntaliitto = this.isResearchCodeOfKuntaliitto(research.properties.number);
    });

    this.application.with.subcontracts.forEach(subcontract => {
      subcontract.with.researches.forEach(research => {
        research.properties.researchCodeOfKuntaliitto = this.isResearchCodeOfKuntaliitto(research.properties.number);
      });
    });
  }

  isResearchCodeOfKuntaliitto(code: string): boolean {
    return this.researchTemplate.findIndex(elem => (code === elem.properties.number)) !== -1;
  }

  buildApplicationFind(): ApplicationFind {
    const applicationFind = new ApplicationFind();
    applicationFind.with.contacts = true;
    applicationFind.with.researches = true;
    applicationFind.with.laboratory = new LaboratoryFindWith();
    applicationFind.with.laboratory.validSupervisedApplications = new ApplicationFindWith();
    applicationFind.with.laboratory.validSupervisedApplications.laboratory = new LaboratoryFindWith();
    applicationFind.with.subcontracts = new SubcontractFindWith();
    applicationFind.with.subcontracts.laboratory = true;
    applicationFind.with.subcontracts.researches = true;
    applicationFind.with.statement = true;
    return applicationFind;
  }

  print() {
    window.print();
  }

  goBack() {
    this.location.back();
  }

  chunkResearches(researches: Research[]) {
    return _.chunk(researches, this.numOfResearchesOnPage) as Research[][];
  }

  chunkLaboratories(laboratories: Laboratory[]) {
    return _.chunk(laboratories, this.numOfLaboratoriesOnPage) as Laboratory[][];
  }

  countStringForResearches(researches: Research[], pageIndex: number) {
    const chunks = this.chunkResearches(researches);
    return this.countString(researches, chunks, pageIndex);
  }

  countStringForLaboratories(laboratories: Laboratory[], pageIndex: number) {
    const chunks = this.chunkLaboratories(laboratories);
    return this.countString(laboratories, chunks, pageIndex);
  }

  countString(all: any[], chunks: any[][], pageIndex: number) {
    const lowerCount = chunks
      .slice(0, pageIndex)
      .reduce((acc, cur) => acc + cur.length, 0);

    const upperCount = chunks
      .slice(0, pageIndex + 1)
      .reduce((acc, cur) => acc + cur.length, 0);

    return (lowerCount + 1) + '-' + upperCount + ' / ' + all.length;
  }

  domesticSubcontractResearches() {
    const subcontracts = this.application.with.subcontracts
      .filter(subcontract => (subcontract.properties.laboratoryId !== null));

    return this.subcontractResearches(subcontracts);
  }

  foreignSubcontractResearches() {
    const subcontracts = this.application.with.subcontracts
      .filter(subcontract => (subcontract.properties.unknownLaboratoryName !== null));

    return this.subcontractResearches(subcontracts);
  }

  subcontractResearches(subcontracts: Subcontract[]) {
    return _.flatMap(subcontracts, subcontract => {
      subcontract.with.researches.forEach(research => {
        research.with = new ResearchWith();
        research.with.subcontract = subcontract;
      });

      return subcontract.with.researches;
    }) as Research[];
  }

  categoryOfLicense(category: number): string {
    if (category >= 1 && category <= 3) {
      return this.translateService.instant('registerExtract.category' + category);
    }
    return category + ""; // shouldn't happen
  }
}
