import {Entity} from '../../base/model/entity';
import {SubcontractProperties} from './subcontract-properties';
import {SubcontractWith} from './subcontract-with';

export class Subcontract extends Entity<SubcontractProperties, SubcontractWith> {
  constructor() {
    super();

    this.properties = new SubcontractProperties();
    this.with = new SubcontractWith();
  }
}
