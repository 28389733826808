import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Service } from '../../base/service/service';
import { StatementTextTemplate } from '../model/statement-text-template';
import { StatementTextTemplateProperties } from '../model/statement-text-template-properties';
import { StatementTextTemplateSaveResponse } from '../model/statement-text-template-save-response';

@Injectable({
  providedIn: 'root'
})
export class StatementTextTemplateService extends Service<StatementTextTemplate> {
  constructor(
    public http: HttpClient
  ) {
    super(
      'api/statement-template',
      http
    );
  }

  save(properties: StatementTextTemplateProperties) {
    return this.http.post<StatementTextTemplateSaveResponse>(this.baseUrl + '/save', properties);
  }
}
