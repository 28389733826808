import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeonly'
})
export class TimeOnlyPipe implements PipeTransform {

  transform(value: number | Date) {
    if (!value) { return; }

    return new Date(value).toLocaleString('fi', {
      hour: 'numeric',
      minute: 'numeric'
    });
  }
}
