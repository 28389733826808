import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { StatementSignerTemplateProperties } from '../model/statement-signer-template-properties';
import { StatementSignerTemplateService } from '../service/statement-signer-template.service';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { StatementSignerTemplateFind } from '../model/statement-signer-template-find';

@Component({
  selector: 'app-statement-signer',
  templateUrl: './statement-signer.component.html',
  styleUrls: ['./statement-signer.component.scss']
})
export class StatementSignerComponent implements OnInit {

  @Input()
  signer: string;

  @Input()
  firstSigner: boolean;

  @Output()
  changedSigner = new EventEmitter<string>();

  signerOptions: StatementSignerTemplateProperties[];
  signerIndex: number = 0;
  editableSigner: string;
  edit: boolean = false;

  constructor(
    private statementSignerTemplateService: StatementSignerTemplateService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    let find = new StatementSignerTemplateFind();
    find.where.firstSigner = this.firstSigner;
    this.statementSignerTemplateService.find(find).subscribe(statementSignerTemplate => {
      this.signerOptions = statementSignerTemplate.map(signer => signer.properties);
      if (this.signer) {
        this.signerIndex = this.signerOptions.findIndex(signer => signer.signer === this.signer);
        if (this.signerIndex === -1) {
          let signer: StatementSignerTemplateProperties = new StatementSignerTemplateProperties();
          signer.signer = this.signer;
          signer.firstSigner = this.firstSigner;
          this.signerOptions.unshift(signer);
          this.signerIndex = 0;
        }
      } else if (this.signerOptions.length > 0) {
        this.signer = this.signerOptions[0].signer;
        this.changedSigner.emit(this.signer);
      }
    });
  }

  signerSelectChange(signerIndex) {
    this.signerIndex = signerIndex;
    this.signer = this.signerOptions[signerIndex].signer;
    this.changedSigner.emit(this.signer);
  }

  modify() {
    this.editableSigner = '';
    this.edit = true;
  }

  saveSigner() {
    this.edit = false;
    let signer = new StatementSignerTemplateProperties();
    signer.firstSigner = this.firstSigner;
    signer.signer = this.editableSigner;

    this.statementSignerTemplateService.save(signer).subscribe(response => {
      if (!response.validation.valid) {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('statement.savingSignerFailed')
        });
      } else {
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('statement.savingSignerSucceeded')
        });
        signer = response.properties;
        this.signerOptions.push(signer);
        this.signerSelectChange(this.signerOptions.length - 1);
      }
    });
  }

  cancelSigner() {
    this.edit = false;
  }
}
