import { ContactProperties } from './contact-properties';
import { TallyProperties } from './tally-properties';
import { AviProperties } from '../../avi/model/avi-properties';
import {Subcontract} from './subcontract';
import {Research} from './research';
import {StatementProperties} from '../../statement/model/statement-properties';
import {Laboratory} from '../../laboratory/model/laboratory';
import {Application} from './application';

export class ApplicationWith {
  contacts: ContactProperties[];
  tallies: TallyProperties[];
  researches: Research[];
  laboratory: Laboratory;
  avi: AviProperties;
  subcontracts: Subcontract[];
  statement: StatementProperties;
  validSupervisedApplications: Application[];
  supervisingLaboratory: Laboratory;
  totalNumberOfResearchesPerYear: number;
}
