import { SubcontractFindWith } from '../../subcontract/model/subcontract-find-with';
import { LaboratoryFindWith } from '../../laboratory/model/laboratory-find-with';

export class ApplicationFindWith {
  contacts: boolean;
  tallies: boolean;
  researches: boolean;
  laboratory: LaboratoryFindWith;
  avi: boolean;
  subcontracts: SubcontractFindWith;
  supervisingLaboratory: LaboratoryFindWith;
  statement: boolean;
  totalNumberOfResearchesPerYear: boolean;
}
